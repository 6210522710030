<template>
  <v-dialog
    v-model="dialog"
    max-width="300px"
  >
    <template v-slot:activator="{ on: vDialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: vTooltip }">
          <v-btn
            color="primary"
            v-bind="attrs"
            small
            v-on="{...vTooltip, ...vDialog}"
          >
            <span v-if="technicalReviewType === null">
              New type
            </span>
            <span v-else>
              <v-icon small>
                mdi-pencil
              </v-icon>
            </span>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>Product type</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="inputs.label"
          :error-messages="errors.label"
          label="Label"
          placeholder="Type label"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          text
          :loading="loading"
          @click="submit"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TechnicalReviewTypeDialog',
  props: {
    technicalReviewType: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      inputs: {},
      errors: {},
    }
  },
  mounted() {
    this.inputs.label = this.technicalReviewType?.label
  },
  methods: {
    submit() {
      if (this.technicalReviewType === null) {
        this.request({
          method: 'POST',
          url: `technical-reviews/types`,
          data: { ...this.inputs },
        })
      } else {
        this.request({
          method: 'PUT',
          url: `technical-reviews/types/${this.technicalReviewType.id}`,
          data: { ...this.inputs },
        })
      }
    },
    request(params) {
      this.loading = true
      this.axios(params).then(response => {
        this.$emit('update:technical-review-type', response.data.data)
        this.dialog = false
      }).catch(error => {
        this.errors = error.response.data?.errors
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
