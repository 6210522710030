<template>
  <v-dialog
    v-model="dialog"
    max-width="300px"
  >
    <template v-slot:activator="{ on: vDialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: vTooltip }">
          <v-btn
            color="primary"
            v-bind="attrs"
            small
            v-on="{...vTooltip, ...vDialog}"
          >
            <span v-if="user === null">
              New user
            </span>
            <span v-else>
              <v-icon small>
                mdi-pencil
              </v-icon>
            </span>
          </v-btn>
        </template>
        <span v-if="user === null">New user</span>
        <span v-if="user !== null">Edit</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>User</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="inputs.firstName"
          :error-messages="errors.firstName"
          label="First name"
          placeholder="John"
        />
        <v-text-field
          v-model="inputs.lastName"
          :error-messages="errors.lastName"
          label="Last name"
          placeholder="Doe"
        />
        <v-text-field
          v-model="inputs.email"
          :error-messages="errors.email"
          label="Email address"
          placeholder="hello@example.com"
        />
        <v-text-field
          v-model="inputs.password"
          :error-messages="errors.password"
          label="New password"
          placeholder="Password"
        />
        <v-select
          v-model="inputs.profileId"
          :error-messages="errors.profileId"
          label="Role"
          :items="profileTypes"
        />
        <v-checkbox
          v-model="inputs.active"
          :error-messages="errors.active"
          label="Active"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          text
          :loading="loading"
          @click="submit"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { cleanObject } from '@/helpers/utils'

export default {
  name: 'UserDialog',
  props: {
    user: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      inputs: {},
      errors: {},
      profileTypes: [
        {
          text: 'Customer support',
          value: 4,
        },
        {
          text: 'User',
          value: 2,
        },
        {
          text: 'Super admin',
          value: 1,
        },
      ],
    }
  },
  mounted() {
    this.inputs.firstName = this.user?.firstName
    this.inputs.lastName = this.user?.lastName
    this.inputs.email = this.user?.email
    this.inputs.profileId = this.user?.profile.id
    this.inputs.password = null
    this.inputs.active = this.user?.active ?? true
  },
  methods: {
    submit() {
      if (this.user === null) {
        this.request({
          method: 'POST',
          url: `users-bo`,
          data: { ...cleanObject(this.inputs) },
        })
      } else {
        this.request({
          method: 'PUT',
          url: `users-bo/${this.user.id}`,
          data: { ...cleanObject(this.inputs) },
        })
      }
    },
    request(params) {
      this.loading = true
      this.axios(params).then(response => {
        this.$emit('update:user', response.data.data)
        this.dialog = false
      }).catch(error => {
        this.errors = error.response.data?.errors
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
