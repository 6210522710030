<template>
  <span @click="deleteItem">
    <slot>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            color="error"
            small
            v-on="on"
          >
            <v-icon small>
              mdi-trash-can
            </v-icon>
          </v-btn>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </slot>
  </span>
</template>

<script>
export default {
  name: 'DeleteButton',
  props: {
    route: {
      required: true,
      type: String,
    },
    method: {
      required: false,
      type: String,
      default: 'DELETE',
    },
    confirmation: {
      required: false,
      type: String,
      default: 'Do you really want to delete this element?',
    },
  },
  methods: {
    deleteItem() {
      if (confirm(this.confirmation)) {
        this.axios.delete(this.route).then(response => {
          this.$emit('delete')
        }).catch(error => {
          alert('This element can not be deleted')
        })
      }
    },
  },
}
</script>
