<template>
  <div>
    <v-row>
      <v-col class="text-h5">
        Users
      </v-col>
      <v-col class="text-right">
        <user-dialog
          @update:user="users.unshift($event)"
        />
      </v-col>
    </v-row>

    <div>
      <v-select
        v-model="query.perPage"
        class="d-inline-block"
        :disabled="loading"
        :items="[5, 10, 20, 50]"
        label="Items per page"
        dense
      />
    </div>

    <v-data-table
      :headers="headers"
      :items="users"
      class="elevation-1"
      :loading="loading"
      hide-default-footer
      :page.sync="query.page"
      :items-per-page="query.perPage"
    >
      <template v-slot:item.name="{ item }">
        {{ item.firstName }}
        {{ item.lastName }}
      </template>
      <template v-slot:item.active="{ item }">
        <active-badge :status="item.active" />
      </template>
      <template v-slot:item.profile.createdAt="{ item }">
        {{ item.profile.createdAt | date }}
      </template>
      <template v-slot:item.actions="{ item }">
        <user-dialog
          v-if="item.id !== undefined"
          :user.sync="users[users.indexOf(item)]"
        />
        <span class="mr-1" />
        <delete-button
          :route="`users-bo/${item.id}`"
          @delete="$delete(users, users.indexOf(item))"
        />
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="query.page"
        :disabled="loading"
        :length="meta.last_page"
        total-visible="7"
      />
    </div>
  </div>
</template>

<script>
import ActiveBadge from '@/views/ui/ActiveBadge'
import DeleteButton from '@/views/ui/DeleteButton'
import UserDialog from '@/views/UserDialog'

export default {
  name: 'Users',
  components: {
    ActiveBadge,
    DeleteButton,
    UserDialog,
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Email',
          value: 'email',
          sortable: false,
        },
        {
          text: 'Profile',
          value: 'profile.name',
          sortable: false,
        },
        {
          text: 'Active',
          value: 'active',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Date created',
          value: 'profile.createdAt',
          sortable: false,
        },
        {
          text: null,
          value: 'actions',
          sortable: false,
          align: 'right',
        },
      ],
      query: {
        page: 1,
        perPage: 10,
      },
      users: [],
      meta: {},
    }
  },
  watch: {
    'query.page'() {
      this.getUsers()
    },
    'query.perPage'() {
      this.query.page = 1
      this.getUsers()
    },
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      this.loading = true

      this.axios.get('users-bo', {
        params: {
          page: this.query.page,
          resultsPerPage: this.query.perPage,
        },
      }).then(response => {
        this.users = response.data.data
        this.meta = response.data.meta
      }).catch(error => {
        console.error(error)
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
