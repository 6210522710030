var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"text-h5"},[_vm._v(" Users ")]),_c('v-col',{staticClass:"text-right"},[_c('user-dialog',{on:{"update:user":function($event){return _vm.users.unshift($event)}}})],1)],1),_c('div',[_c('v-select',{staticClass:"d-inline-block",attrs:{"disabled":_vm.loading,"items":[5, 10, 20, 50],"label":"Items per page","dense":""},model:{value:(_vm.query.perPage),callback:function ($$v) {_vm.$set(_vm.query, "perPage", $$v)},expression:"query.perPage"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"loading":_vm.loading,"hide-default-footer":"","page":_vm.query.page,"items-per-page":_vm.query.perPage},on:{"update:page":function($event){return _vm.$set(_vm.query, "page", $event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('active-badge',{attrs:{"status":item.active}})]}},{key:"item.profile.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.profile.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.id !== undefined)?_c('user-dialog',{attrs:{"user":_vm.users[_vm.users.indexOf(item)]},on:{"update:user":function($event){_vm.$set(_vm.users, _vm.users.indexOf(item), $event)}}}):_vm._e(),_c('span',{staticClass:"mr-1"}),_c('delete-button',{attrs:{"route":("users-bo/" + (item.id))},on:{"delete":function($event){_vm.$delete(_vm.users, _vm.users.indexOf(item))}}})]}}])}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"disabled":_vm.loading,"length":_vm.meta.last_page,"total-visible":"7"},model:{value:(_vm.query.page),callback:function ($$v) {_vm.$set(_vm.query, "page", $$v)},expression:"query.page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }