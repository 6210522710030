<template>
  <div>
    <v-row>
      <v-col class="text-h5">
        Product types
      </v-col>
      <v-col class="text-right">
        <technical-review-type-dialog
          @update:technical-review-type="technicalReviewTypes.unshift($event)"
        />
      </v-col>
    </v-row>

    <v-card>
      <v-data-table
        :headers="headers"
        :items="technicalReviewTypes"
        class="elevation-1"
        :loading="loading"
        hide-default-footer
        :items-per-page="query.perPage"
      >
        <template v-slot:item.actions="{ item }">
          <technical-review-type-dialog
            v-if="item.id !== undefined"
            :technical-review-type.sync="technicalReviewTypes[technicalReviewTypes.indexOf(item)]"
          />
          <span class="mr-1" />
          <delete-button
            :route="`technical-reviews/types/${item.id}`"
            @delete="$delete(technicalReviewTypes, technicalReviewTypes.indexOf(item))"
          />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import DeleteButton from '@/views/ui/DeleteButton'
import TechnicalReviewTypeDialog from '@/views/TechnicalReviewTypeDialog'

export default {
  name: 'TechnicalReviewTypes',
  components: {
    DeleteButton,
    TechnicalReviewTypeDialog,
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: 'Name',
          value: 'label',
          sortable: true,
        },
        {
          text: null,
          value: 'actions',
          sortable: false,
          align: 'right',
        },
      ],
      technicalReviewTypes: [],
      query: {
        page: 1,
        perPage: 1000,
      },
    }
  },
  mounted() {
    this.getTechnicalReviewTypes()
  },
  methods: {
    getTechnicalReviewTypes() {
      this.loading = true

      this.axios.get('technical-reviews/types', {
        params: {
          page: this.query.page,
          resultsPerPage: this.query.perPage,
        },
      }).then(response => {
        this.technicalReviewTypes = response.data.data
      }).catch(error => {
        console.error(error)
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
